import React, { Component } from "react";
import Fade from "react-reveal";
import craft from "./photos/Ceifa.jpg";
import logoVimDoMonte from "./photos/LogoVimDoMonte1.png";

class Inicio extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <header id="home" style={{
        backgroundImage: `url(${craft})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}>
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Início
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                Sobre nós
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#processo">
                Processo
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#produtos">
                Produtos
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#testimonials">
                Testemunhos
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Parceiros
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                Contacte-nos
              </a>
            </li>
          </ul>
        </nav>

        <Fade bottom duration={1200}>
        <div className="row banner">
          <div className="banner-text">
            <img src={logoVimDoMonte} alt="logo" className="center" />
          </div>
        </div>
        </Fade>


        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header >
    );
  }
}

export default Inicio;
