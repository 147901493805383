import React, { Component } from "react";
import Slide from "react-reveal";

class Parceiros extends Component {
  render() {
    if (!this.props.data) return null;

    const partners = this.props.data.partners;

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row">
            <h2>
              Parceiros.
            </h2>
            {/*<div className="three columns header-col">
              <h1>
                <span>Parceiros.</span>
              </h1>
    </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{partners}</div>
              </div>
            </div>*/}

            <div className="column">
              <div key={partners[0].partner}>
                {/*<h3>{partners[0].partner}</h3>*/}
                <img
                  src={partners[0].image}
                  alt="Nordic Giant Profile Pic"
                  width="279"
                  height="249"
                  className="center1"
                />

               <p className="info">
                  {partners[0].degree} {/*<span>&bull;</span>
                   <em className="date">{partners[0].since}</em>*/}
                </p>

                <p><a href={partners[0].url}>Website</a></p>
                <p align="justify">{partners[0].description}</p>

              </div>
            </div>

            <div className="column">
              <div key={partners[1].partner}>
                <img
                  src={partners[1].image}
                  alt="Nordic Giant Profile Pic"
                  width="400"
                  height="600"
                  className="center1"
                />

                <p className="info">
                  {partners[1].degree} {/*<span>&bull;</span>
                  <em className="date">{partners[1].since}</em>*/}
                </p>

                <p><a href={partners[1].url}>Website</a></p>

                <p align="justify">{partners[1].description}</p>
              </div>
            </div>
          </div>
        </Slide>

        {/*
        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skills</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
    </Slide>*/}

      </section >

    );
  }
}

export default Parceiros;
