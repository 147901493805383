import React, { Component } from "react";
import Fade from "react-reveal";

class Sobre extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <h1> Sobre nós.</h1>

            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Nordic Giant Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <p>{bio}</p>
              {/*<div className="row">
                <div className="columns contact-details">
                  <h2>Contactos</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
    </div>*/}
            </div>
          </div>
        </Fade>
      </section >
    );
  }
}

export default Sobre;
