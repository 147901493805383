import React, { useState, useEffect } from "react";
import { Fade, Slide } from "react-reveal";
import { AiFillPhone } from 'react-icons/ai';
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import Insta from "./Insta";

const Contacto = (props) => {
  const [values, setValues] = useState({
    contactName: '',
    contactEmail: '',
    contactSubject: '',
    contactMessage: ''
  });
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = 'service_u2j33mb'; // Replace with your service ID
    const templateId = 'template_kprpz0q'; // Replace with your template ID
    const publicKey = 'zJ92ckXtwVJ1ovRiG';

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        contactSubject: values.contactSubject,
        contactMessage: values.contactMessage,
      },publicKey);
      setStatus('SUCCESS');
    } catch (error) {
      console.log('Error sending email:', error);
      setStatus('FAILED');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
        setValues({
          contactName: '',
          contactEmail: '',
          contactSubject: '',
          contactMessage: '',
        });
      }, 9000);
    }
  }, [status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  if (!props.data) return null;

  const street = props.data.address.street;
  const city = props.data.address.city;
  const state = props.data.address.state;
  const zip = props.data.address.zip;
  const phone = props.data.phone;
  const message = props.data.contactmessage;

  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Fale connosco.</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">{message}</p>
          </div>
        </div>
      </Fade>

      <div className="row">
        <Slide left duration={1000}>
          <div className="eight columns">
            <form onSubmit={handleSubmit} action="" method="post" id="contactForm" name="contactForm">
              <fieldset>
                <div>
                  <label htmlFor="contactName">
                    Nome <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={values.contactName}
                    onChange={handleChange}
                    name="contactName" // Specify the 'name' attribute
                    id="contactName"
                  />
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    value={values.contactEmail}
                    onChange={handleChange}
                    name="contactEmail" // Specify the 'name' attribute
                    id="contactEmail"
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">Assunto</label>
                  <input
                    type="text"
                    value={values.contactSubject}
                    onChange={handleChange}
                    name="contactSubject" // Specify the 'name' attribute
                    id="contactSubject"
                  />
                </div>

                <div>
                  <label htmlFor="contactMessage">
                    Mensagem <span className="required">*</span>
                  </label>
                  <textarea
                    cols="40"
                    rows="18"
                    value={values.contactMessage}
                    onChange={handleChange}
                    name="contactMessage" // Specify the 'name' attribute
                    id="contactMessage"
                  ></textarea>
                </div>

                <div>
                  <button className="submit" type="submit" value="Submit" disabled={loading}>
                    {loading ? "A enviar..." : "Submeter"}
                  </button>
                  <span id="image-loader">
                    <img alt="" src="images/loader.gif" />
                  </span>
                </div>
              </fieldset>
            </form>

            {status === 'SUCCESS' && (
              <Alert className="alert" severity="success">A sua mensagem foi enviada com sucesso!</Alert>
            )}

            {status === 'FAILED' && (
              <Alert className="alert" severity="error">Lamentamos mas não foi possível enviar a sua mensagem. Contacte-nos via telefone, redes sociais ou presencialmente.</Alert>
            )}
          </div>
        </Slide>

        <Slide right duration={1000}>
        <aside className="four columns footer-widgets">
          <h4>Morada e Telemóvel.</h4>
            <div className="widget widget_contact">
              <p className="address">
                {street} <br />
                {city}, {state} {zip}
                <br /><br></br>
                <iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d568.09399390897!2d-7.392900967536562!3d40.51377858112301!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3ce6fdb5c3be17%3A0x7811572f9461cbd2!2sAv.%20da%20Cruz%207%2C%206300-245%20Videmonte!5e0!3m2!1spt-PT!2spt!4v1669134748366!5m2!1spt-PT!2spt" width="250" height="200" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <br />
                <span><AiFillPhone /> {phone}</span>
              </p>
            </div>

            <div className="widget widget_tweets">
              <ul id="twitter">
                <Insta />
              </ul>
            </div>
          </aside>
        </Slide>
      </div>
    </section>
  );
}

export default Contacto;
