import React, { useState, useEffect } from "react";
import styled from "@emotion/styled"
const Card = styled.img`
  width: 250px;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
`
const url =
    'https://www.instagram.com/graphql/query/?query_hash=42323d64886122307be10013ad2dcc44&variables={"id":44724612209,"first":2}';
    
const Insta = () => {
    const [insta, setInsta] = useState([]);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        fetch(url)
            .then((data) => data.json())
            .then((data) => {
                if(data.status === "ok"){
                    setTitle(<h4 className="widget-title">Últimos posts</h4>);
                }
                else{
                    return;
                }            
                const photosArray = data.data.user.edge_owner_to_timeline_media.edges;
                setInsta(photosArray);
            });
    }, []);
    
    return (
        <Grid>
            {title}
            {insta.map((photo) => (
                <Card crossorigin="anonymous" src={photo.node.display_url} key={photo.node.id} />
            ))}
        </Grid>
    );
};
export default Insta;