import React from "react";
import {useState} from 'react';
import Heart from "react-heart"

import Fade from "react-reveal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

let id = 0;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#fff',
  backgroundColor: 'white',
  border: '2px solid #fff',
  boxShadow: 4,
  p: 4,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "addOne":
      return { ...state, count: state.count + 1 };
    case "subtractOne":
      return { ...state, count: state.count - 1 };
    default:
      throw new Error();
  }
};

function Produtos(props) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [active1, setActive1] = useState(true)
  let [countPao, setCountPao] = useState(0);
  let [countBisc, setCountBisc] = useState(0);
  let [countMel, setCountMel] = useState(0);
  let [countInf, setCountInf] = useState(0);


  const handleOpen = (title, description) => {
    setOpen(true);
    setTitle(title);
    setDescription(description);
  };

  const handleClose = () => setOpen(false);

  function incrementCountPao() {
    countPao = countPao + 1;
    setCountPao(countPao);
  }
  function decrementCountPao() {
    if (countPao <= 0)
      return;
    else if(countPao > 0)
      countPao = countPao - 1;
      setCountPao(countPao);
  }

  function incrementCountBisc() {
    countBisc = countBisc + 1;
    setCountBisc(countBisc);
  }
  function decrementCountBisc() {
    if (countBisc <= 0)
      return;
    else if(countBisc > 0)
    countBisc = countBisc - 1;
      setCountBisc(countBisc);
  }
  
  function incrementCountMel() {
    countMel = countMel + 1;
    setCountMel(countMel);
  }
  function decrementCountMel() {
    if (countMel <= 0)
      return;
    else if(countMel > 0)
      countMel = countMel - 1;
      setCountMel(countMel);
  }
  
  function incrementCountInf() {
    countInf = countInf + 1;
    setCountInf(countInf);
  }
  function decrementCountInf() {
    if (countInf <= 0)
      return;
    else if(countInf > 0)
      countInf = countInf - 1;
      setCountInf(countInf);
  }
  
  if (!props.data) return null;

  const projects = props.data.projects.map(
    function (projects) {
      let projectImage = "images/produtos/" + projects.image;

      let gallery = null;
      {/*if (projects.title === "As Infusões.") {
        gallery = <SimpleImageSlider
          style={{ margin: '0 auto', marginTop: '40px' }}
          width={466}
          height={594}
          images={images}
          showBullets={false}
          showNavs={true}
        />;
        //<Gallery source={source} itemsToShow={6} itemsToShowInPreview={3} />;
      }*/}


      let no_gallery = null;
      if (projects.title !== "As Infusõesj.") {
        no_gallery = <div key={id++} className="item-wrap">
          <img alt={projects.title} src={projectImage} />
        </div>
        //<Gallery source={source} itemsToShow={6} itemsToShowInPreview={3} />;
      }

      return (
        
        <div key={id++} className="container">
          
          <div className="columns produtos-item">
            {no_gallery}
            {gallery}
          </div>

          {/*align="justify"*/}
          <div key={id++} className="margin">
            <h2>{projects.title}</h2>
            <p>{projects.description}</p>
          </div>
          
        </div>
      );
    });

  return (
    <section id="produtos">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">

            <h1>Os nossos produtos.</h1>

            <div
              key={id++}
              id="produtos-wrapper"
              className=" s-bgrid-thirds cf"
            >
                {projects}

            </div>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h7" component="h8" >
                  {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      </Fade>
    </section >
  );
}


export default Produtos;
