import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Inicio from "./Components/Inicio";
import Rodapé from "./Components/Rodapé";
import Sobre from "./Components/Sobre";
import Parceiros from "./Components/Parceiros";
import Contactos from "./Components/Contacto";
import Produtos from "./Components/Produtos";
import Processo from "./Components/Processo";
import Testemunhos from "./Components/Testemunhos";
import YoutubeEmbed from "./Components/YoutubeEmbed";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQLK1Cycfr_KCSsLrbFQsma3-7iINQr5k",
  authDomain: "vim-do-monte.firebaseapp.com",
  projectId: "vim-do-monte",
  storageBucket: "vim-do-monte.appspot.com",
  messagingSenderId: "468998903730",
  appId: "1:468998903730:web:0ee8f1699f4d13f02ab6ab",
  measurementId: "G-FKKFMTR7BJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    /**
 * @config preparing config prop.
 * api: url for the server endpoint
 * title: form title
 * successMessage: message will show in the UI when mail is successfully sent.
 * errorMessage: message will show in the UI when mail is not sent.
 * fields: this is the name of each field. This should be exact order of the fieldsConfig and fieldsConfig.fieldName should be  the same
 * fieldsConfig = settings for each input/textarea field
 */
    const config = {
      api: `${process.env.REACT_APP_API}`,
      title: 'Fale connosco.',
      description: 'Contacte-nos para efetuar uma encomenda ou para esclarecer qualquer dúvida que tenha. Prometemos responder tão rápido quanto possível.',
      street: 'Avenida da Cruz nº7',
      city: 'Videmonte',
      state: 'Guarda',
      zip: '6300-245',
      phone: '+351 963 530 929',
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        msg: ''
      },
      fieldsConfig: [
        { id: 1, label: 'Nome', fieldName: 'firstName', type: 'text', placeholder: 'O seu nome', isRequired: true, klassName: 'first-name-field' },
        { id: 2, label: 'Assunto', fieldName: 'lastName', type: 'text', placeholder: 'O assunto', isRequired: true, klassName: 'last-name-field' },
        { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: 'O seu email', isRequired: true, klassName: 'email-field' },
        { id: 4, label: 'Mensagem', fieldName: 'msg', type: 'textarea', placeholder: 'Escreva alguma coisa...', isRequired: true, klassName: 'message-field' }
      ]
    }

    return (
      <div className="App">
        <Inicio data={this.state.resumeData.main} />
        <Sobre data={this.state.resumeData.main} />
        <Processo data={this.state.resumeData.main} />
        <Produtos data={this.state.resumeData.produtos} />
        <Testemunhos data={this.state.resumeData.testimonials} />
        <Parceiros data={this.state.resumeData.resume} />
        <YoutubeEmbed data={this.state.resumeData.main}/>
        <Contactos config={config} data={this.state.resumeData.main} />
        <Rodapé data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
