import React from "react";
import Fade from "react-reveal";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Processo(props) {
    if (!props.data) return null;

    return (
        <section id="processo">
            <Fade left duration={1000} >
            <div className = "head-text">

                <div className="row">
                    <h1>O processo do pão.</h1>
                    <br></br>
                    <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} showStatus={false} showIndicators={false} showThumbs={false}>
                        <div>
                            <img alt="fermento" src="images/produtos/07.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="1" src="images/produtos/08.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="2" src="images/produtos/081.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="3" src="images/produtos/09.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="4" src="images/produtos/10.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="5" src="images/produtos/12.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                        <div>
                            <img alt="6" src="images/produtos/13.jpg" />
                            <div class='text-on-image'>
                                <p>&copy; Tiago Cerveira</p>
                            </div>
                        </div>
                    </Carousel>
                    </div>
                    </div>

            </Fade>
        </section >
    );
}


export default Processo;
